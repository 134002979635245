<template>
  <div>
    <el-card style="margin-top: 10px;" shadow="never">
      <div>
        <i class="el-icon-search"></i>
        <span>筛选搜索</span>
        <el-button
            style="float: right"
            @click="handleSearchList()"
            type="primary"
            size="small">
          查询结果
        </el-button>
        <el-button
            style="float: right;margin-right: 15px"
            @click="handleResetSearch()"
            size="small">
          重置
        </el-button>
      </div>
      <div style="margin-top: 15px">
        <el-form :inline="true" :model="listQuery" size="small" label-width="140px">
          <el-form-item label="输入搜索：">
            <el-input style="width: 203px" v-model="listQuery.keyword" placeholder="手机号"></el-input>
          </el-form-item>
          <el-form-item label="访问用户类型：">
            <el-select v-model="listQuery.role" class="input-width" placeholder="全部" clearable>
              <el-option v-for="(item, index) in itemRoleOptions"
                         :key="index"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否已电联">
            <el-select v-model="listQuery.callFlag" class="input-width" placeholder="全部" clearable>
              <el-option v-for="(item, index) in itemFlagOptions"
                         :key="index"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
    <el-card class="operate-container" shadow="never">
      <i class="el-icon-tickets"></i>
      <span>数据列表</span>
      <el-button size="mini" type="info" plain @click="handleBackToTop()" style="margin-left: 20px">
        返回上级
      </el-button>
      <!--      <el-button size="mini" class="btn-add" @click="handleAdd()" style="margin-left: 20px">添加</el-button>-->
    </el-card>
    <div class="table-container">
      <el-table ref="itemTable"
                :data="list"
                style="width: 100%;"
                v-loading="listLoading" border>
        <el-table-column label="编号" width="100" align="center">
          <template slot-scope="scope">{{ scope.$index + 1 }}</template>
        </el-table-column>
        <el-table-column label="微信授权手机号" align="center">
          <template slot-scope="scope">{{ scope.row.autoPhone ? scope.row.autoPhone : '无' }}</template>
        </el-table-column>
        <el-table-column label="初次查看时间" align="center">
          <template slot-scope="scope">{{ scope.row.createTime }}</template>
        </el-table-column>
        <el-table-column label="用户类型" align="center">
          <template slot-scope="scope">{{ itemRoleLabel[scope.row.roleType] }}<span><el-button
              size="mini" type="text"
              @click="changeRole(scope.row)">更改
              </el-button></span></template>
        </el-table-column>
        <el-table-column label="提交的姓名" align="center">
          <template slot-scope="scope">{{ scope.row.submitName ? scope.row.submitName : '无' }}</template>
        </el-table-column>
        <el-table-column label="提交的手机号" align="center">
          <template slot-scope="scope">{{ scope.row.submitPhone ? scope.row.submitPhone : '无' }}</template>
        </el-table-column>
        <el-table-column label="分享者手机号" align="center">
          <template slot-scope="scope">{{ scope.row.sharePhone ? scope.row.sharePhone : '无' }}</template>
        </el-table-column>
        <el-table-column label="累计查看次数" align="center">
          <template slot-scope="scope">{{ scope.row.viewCount }}</template>
        </el-table-column>
        <el-table-column label="最近查看时间" align="center">
          <template slot-scope="scope">{{ scope.row.lastViewTime }}</template>
        </el-table-column>
        <el-table-column label="已电联" align="center">
          <template slot-scope="scope">{{ scope.row.callFlag ? '是' : '否' }}<span><el-button
              size="mini" type="text"
              @click="changeFlag(scope.row)">更改
              </el-button></span></template>
        </el-table-column>
        <el-table-column label="已预约" align="center">
          <template slot-scope="scope">{{ scope.row.callSuccess ? '是' : '否' }}</template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-container">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, sizes,prev, pager, next,jumper"
          :current-page.sync="listQuery.pageNum"
          :page-size="listQuery.pageSize"
          :page-sizes="[10,15,20]"
          :total="total">
      </el-pagination>
    </div>
    <el-dialog
        title="更改用户类型"
        :visible.sync="dialogVisible"
        width="40%">
      <el-form ref="editItemForm"
               label-width="180px" size="small">
        <div class="form-part-section">
          <el-form-item label="用户类型" required>
            <el-select v-model="selRole" class="input-width" placeholder="请选择">
              <el-option
                  v-for="item in itemRoleOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="handleDialogConfirm()" size="small">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
        title="更改电联状态"
        :visible.sync="flagDialogVisible"
        width="40%">
      <el-form ref="callFlagForm"
               label-width="150px" size="small">
        <el-form-item label="是否已联系">
          <el-switch
              v-model="selFlag"
              @change="triggerFlag"
              active-text="已电"
              inactive-text="待拨">
          </el-switch>
        </el-form-item>
        <el-form-item label="是否已预约">
          <el-switch
              :disabled="!selFlag"
              v-model="selSuccess"
              active-text="已约"
              inactive-text="未约">
          </el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="flagDialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="handleFlagConfirm()" size="small">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
const defaultListQuery = {
  pageNum: 1,
  pageSize: 10,
  keyword: null,
  callFlag: null,
  role: null
};

export default {
  name: "shareCustList",
  data() {
    return {
      shareId: null,
      listQuery: Object.assign({}, defaultListQuery),
      list: null,
      total: null,
      listLoading: false,
      dialogVisible: false,
      flagDialogVisible: false,
      selCustId: null,
      selRole: 0,
      selFlag: false, // 默认 callFlag为false，即未电call过
      selSuccess: false, // 默认 callSuccess为false，即未电call预约成功过
      itemRoleOptions: [{ // 0-客户；1-员工；2-渠道
        value: 0,
        label: '客户'
      }, {
        value: 1,
        label: '员工'
      }, {
        value: 2,
        label: '渠道'
      }],
      itemRoleLabel: {
        0: '客户',
        1: '员工',
        2: '渠道'
      },
      itemFlagOptions: [{ // 0-待拨，1-已拨
        value: 0,
        label: '待拨'
      }, {
        value: 1,
        label: '已拨'
      }],
    }
  },
  created() {
    this.shareId = this.$route.query.id;
    this.getList();
  },
  methods: {
    handleSizeChange(val) {
      this.listQuery.pageNum = 1;
      this.listQuery.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.pageNum = val;
      this.getList();
    },
    handleSearchList() {
      this.listQuery.pageNum = 1;
      this.getList();
    },
    handleResetSearch() {
      this.listQuery = Object.assign({}, defaultListQuery);
    },
    handleBackToTop() {
      this.$router.back();
    },
    changeFlag(row) {
      this.selCustId = row.id;
      this.selFlag = row.callFlag || false;
      this.selSuccess = row.callSuccess || false;
      this.flagDialogVisible = true;
    },
    triggerFlag(val) {
      if (!val) { // 如果未电联，则预约自动更改为未成功
        this.selSuccess = false;
      }
    },
    changeRole(row) {
      this.dialogVisible = true;
      this.selCustId = row.id;
      this.selRole = row.roleType || 0;
    },
    getList() {
      this.listLoading = true;
      this.getRequest('/shareCustomer/getByPage/' + this.shareId, this.listQuery).then(response => {
        this.listLoading = false;
        this.list = response.obj.list;
        this.total = response.obj.total;
      });
    },
    handleFlagConfirm() {
      this.$confirm('是否要确认?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let url = '/shareCustomer/updateCallFlag/' + this.selCustId;
        this.postRequest(url, {flag: this.selFlag, success: this.selSuccess}).then(resp => {
          this.$message({
            message: '更新成功！',
            type: 'success'
          });
          this.flagDialogVisible = false;
          this.getList();
        })
      })
    },
    handleDialogConfirm() {
      this.$confirm('是否要确认?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const _this = this;
        let url = '/shareCustomer/updateRole/' + this.selCustId;
        this.postRequest(url, {role: this.selRole}).then(resp => {
          this.$message({
            message: '保存成功！',
            type: 'success'
          });
          _this.dialogVisible = false;
          _this.getList();
        })
      })
    }
  }
}
</script>

<style scoped>

</style>