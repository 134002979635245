import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from '../components/Login.vue'
import Home from '../views/Home.vue'

import WriteProject from '../views/projects/WriteProject'
import EditProject from '../views/projects/EditProject'
import AllProjects from '../views/projects/AllProjects'
import CategoryProject from '../views/projects/CategoryProject'
import TagProject from '../views/projects/TagProject'
import CommentProject from '../views/projects/CommentProject'
// import LinksProject from '../views/projects/LinksProject'
import EditProjectDev from '../views/projects/developerInfo'
import EditProjectExt from '../views/projects/projectExtInfo'
import projectMembers from '../views/projects/projectMemberList'
import projectItems from '../views/projects/projectItemList'
import subItems from '../views/projects/subItemList'
import EditProjectItem from '../views/projects/projectItemInfo'
import shareList from '../views/projects/shareList'
import shareDetail from '../views/projects/shareDetail'
import shareCustList from '../views/projects/shareCustList'

import userList from '../views/user/userList'
import deptList from '../views/user/deptList'
import serviceList from '../views/user/serviceList'
import subServiceList from '../views/user/subServiceList'
import userCenter from '../views/user/userCenter'
import csmReqList from '../views/user/csmReqList'
import deptMembers from '../views/user/deptMemberList'

import albumList from '../views/org/albumList'
import orgDetail from '../views/org/orgDetail'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  { path: '/',redirect: '/admin',name: 'Login', component: Login, hidden: true  },

  { path: '/admin', name: 'Login', component: Login, hidden: true},
    
  {
    path: '/admin/home',
    name: '项目管理',
    component: Home,
    children:[
      { path: '/admin/writeProject', name: '录入项目',  component: WriteProject },
      { path: '/admin/editProject', name: '编辑项目',  component: EditProject, hidden: true },
      { path: '/admin/editProjectDev', name: '编辑项目开发商',  component: EditProjectDev, hidden: true },
      { path: '/admin/editProjectExt', name: '编辑楼盘信息',  component: EditProjectExt, hidden: true },
      { path: '/admin/projectMembers', name: '编辑配套团队信息',  component: projectMembers, hidden: true },
      { path: '/admin/projectItems', name: '户型信息',  component: projectItems, hidden: true },
      { path: '/admin/subItems', name: '房源信息',  component: subItems, hidden: true },
      { path: '/admin/editProjectItem', name: '编辑户型信息',  component: EditProjectItem, hidden: true },
      { path: '/admin/allProjects', name: '项目列表',  component: AllProjects  },
      { path: '/admin/categoryProject', name: '分类管理',  component: CategoryProject  },
      { path: '/admin/tagProject', name: '标签管理',  component: TagProject  },
      { path: '/admin/commentProject', name: '留言管理',  component: CommentProject  },
      { path: '/admin/shareList', name: '分享列表',  component: shareList  },
      { path: '/admin/shareDetail', name: '编辑分享信息',  component: shareDetail, hidden: true },
      { path: '/admin/shareCustList', name: '获客信息',  component: shareCustList, hidden: true },

    ]
  },
  {
    path: '/admin/user',
    name: '公司管理',
    redirect: '/admin/userList',
    component: Home,
    children:[
      { path: '/admin/userList', name: '用户列表',  component: userList },
      { path: '/admin/deptList', name: '部门列表',  component: deptList },
      { path: '/admin/allServices', name: '企业服务列表',  component: serviceList  },
      { path: '/admin/subServiceList', name: '企业服务列表',  component: subServiceList, hidden: true  },
      { path: '/admin/userCenter', name: '个人中心',  component: userCenter, hidden: true  },
      { path: '/admin/csmReqList', name: '企服需求列表',  component: csmReqList, hidden: true  },
      { path: '/admin/deptMembers', name: '部门员工列表',  component: deptMembers, hidden: true },
    ]
  },
  {
    path: '/admin/org',
    name: '官网管理',
    redirect: '/admin/orgAlbum',
    component: Home,
    children:[
      { path: '/admin/orgAlbum', name: '主图列表',  component: albumList },
      { path: '/admin/orgDetail', name: '企业信息',  component: orgDetail },

    ]
  },
]

const router = new VueRouter({
  routes
})

export default router
