<template>
  <div class="m_container">
    <el-row :gutter="50">
      <el-col :span="24">
        <!-- 项目内容 -->
        <div class="m_content">
          <el-form ref="editForm" status-icon :model="editForm" :rules="projectRules" label-width="180px">
            <div class="form-part-section">
              <el-form-item label="标题" prop="title">
                <el-input v-model="editForm.title"></el-input>
              </el-form-item>
              <el-form-item label="分享简介">
                <el-input v-model="editForm.shareDesc"></el-input>
              </el-form-item>
              <el-form-item label="分享图片">
                <single-upload v-model="editForm.firstPicture"></single-upload>
              </el-form-item>
              <el-form-item
                  v-for="(item, index) in mainTags"
                  :key="index"
                  :label="'项目卖点 ' + (index + 1)"
              >
                <el-input v-model="item.value" style="width: 80%;"></el-input>
                <el-button @click="removeTagInput(index)" style="margin-left: 10px;">移除</el-button>
              </el-form-item>
              <el-form-item label=" ">
              <el-button type="primary" @click="addTagInput">添加卖点</el-button>
              </el-form-item>
              <el-form-item label="咨询电话" prop="contactTel">
                <el-input v-model="editForm.contactTel" style="width: 80%;"></el-input>
              </el-form-item>
              <el-form-item label="项目图册">
                <draggable v-model="albumPics" :animation="100" style="position: relative;margin-bottom: 10px; clear: left">
                  <div v-for="(item, index) in albumPics" :key="index" style="display: inline-block;float: left" class="isimages">
                    <div class="preview_size" style="background: white;">
                      <img
                          alt=""
                          class="preview_size"
                          :src="item.url"
                      >
                      <div class="imgs_prews">
                        <div class="imgs_prew preview_size">
                          <i class="el-icon-zoom-in" @click="handlePictureCardPreview(item.url)" />
                          <span />
                          <i class="el-icon-delete" @click="handleRemove(index)" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <editorImage color="#1890ff" class="editor-upload-btn" @successCBK="albumSuccessCBK"></editorImage>
                </draggable>
              </el-form-item>
              <el-form-item label="项目介绍" prop="content">
                <tinymce ref="mec" :height="300" v-model="editForm.content"></tinymce>
              </el-form-item>
            </div>
            <el-form-item style="margin: 60px; text-align: center;">
              <el-button type="info" v-if="!isEdit" @click="toSubmitForm('editForm', true)">暂时保存</el-button>
              <el-button type="primary" @click="toSubmitForm('editForm', false)">发布项目</el-button>
              <el-button type="info" v-if="isEdit" @click="goBack">取消</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-col>
<!--      <el-col :span="10">-->
        <!-- /**
             * 使用方法
             * ①、如果使用第一种方法，从其他微信编辑器（135或者word文档等等）
             * 需要先把图片上传至第三方图片服务器，然后获取图片链接复制到文档里
             * 例如：private content = '<img src="http://file.haoxinqing.cn/group1/M00/11/61/Chw4wFwAlz2AX_iqAABOSgaernE854.jpg" style=" max-width: 100%; height: auto;">'
             * ②、就是iframe的形式，导入的是一个m端链接
             */  -->
<!--        <vue-phone-preview :content="editForm.content" class="custom-size">-->
<!--        <vue-phone-preview class="custom-size">-->
<!--          <div>-->
<!--            <img src="http://file.haoxinqing.cn/group1/M00/11/61/Chw4wFwAlz2AX_iqAABOSgaernE854.jpg" style=" max-width: 100%; height: auto;">-->
<!--          </div>-->
<!--          <div v-html="editForm.content"></div>-->
<!--        </vue-phone-preview>-->
<!--      </el-col>-->
    </el-row>
    <!-- 图片预览 -->
    <el-dialog :visible.sync="dialogVisible">
      <img
          width="100%"
          :src="dialogImageUrl"
          alt=""
      />
    </el-dialog>
  </div>
</template>

<script>
import SingleUpload from '@/components/Upload/singleUpload'
import Tinymce from '@/components/Tinymce'
import VuePhonePreview from 'vue-phone-preview';
import draggable from 'vuedraggable'
import editorImage from "@/components/Tinymce/components/editorImage.vue";

const defaultShare = {  //项目表单
  id: null,
  relateProject: null,
  projectName: null,
  title: '',
  shareDesc: '',
  firstPicture: '',
  contactTel: '',
  mainTag: '',
  albums: '',
  content: '',
  shareStatement: 0
};

export default {
  name: "shareDetail",
  components: {editorImage, SingleUpload, Tinymce, VuePhonePreview, draggable},
  created() {
    this.initShare();
  },
  data() {
    return {
      editForm: Object.assign({}, defaultShare),
      isEdit: false,
      dialogVisible: false,
      dialogImageUrl: '',
      shareId: null,
      mainTags: [{value:''}],
      albumPics: [],
      projectRules: {  //表单校验
        title: [
          {required: true, message: '请输入分享标题', trigger: 'blur'},
          {min: 3, max: 36, message: '长度在 3 到 36 个字符', trigger: 'blur'}
        ],
        contactTel: [
          {required: true, message: '请输入咨询电话热线', trigger: 'blur'}
        ],
        content: [
          {required: true, message: '请输入项目笔记', trigger: 'blur'}
        ],
      }
    }
  },
  methods: {
    //初始化数据
    initShare() {
      let queryId = this.$route.query.id || null;
      if (queryId) {
        this.isEdit = true;
        this.shareId = queryId;
      } else {
        this.isEdit = false;
        this.shareId = null;
      }
      if (this.isEdit) {
        const _this = this
        this.getRequest('/shareProj/getDetail?id=' + queryId).then(resp => {
          if (!resp.obj) {
            return;
          }
          _this.editForm = resp.obj;
          if (_this.editForm.mainTag) {
            _this.mainTags = []; // 重新初始化
            let tmpTags = _this.editForm.mainTag.split(',');
            for (let tmpTag of tmpTags) {
              _this.mainTags.push({value: tmpTag});
            }
          }
          if (_this.editForm.albums) {
            let tmpAlbs = _this.editForm.albums.split(',');
            for (let tmpAlb of tmpAlbs) {
              _this.albumPics.push({url: tmpAlb});
            }
          }
        })
      }
    },
    addTagInput() {
      this.mainTags.push({value: ''});
    },
    removeTagInput(index) {
      this.mainTags.splice(index, 1);
    },
    albumSuccessCBK(arr) {
      const _this = this
      arr.forEach(v => {
        _this.albumPics.push({url: v.url});
      })
    },
    //预览
    handlePictureCardPreview(url) {
      this.dialogImageUrl = url;
      this.dialogVisible = true;
    },
    //删除
    handleRemove(index) {
      this.albumPics.splice(index, 1);
    },
    goBack() {
      this.$router.back();
    },
    rebuildComponents() {
      this.isResetComponent = false;
      // 重新创建子标签
      let _this = this;
      this.$nextTick(() => {
        _this.isResetComponent = true;
      });
    },
    //校验项目基本内容表单
    toSubmitForm(formName, isTempSave) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const _this = this;
          this.editForm.shareStatement = isTempSave ? 0 : 1;
          let lastAlbs = [];
          for (let tmpItm of this.albumPics) {
            lastAlbs.push(tmpItm.url);
          }
          this.editForm.albums = lastAlbs.join(',');
          let realMainTags = [];
          for (let tmpTag of this.mainTags) {
            if (tmpTag.value && tmpTag.value.trim() !== '') {
              realMainTags.push(tmpTag.value);
            }
          }
          // this.mainTags.filter(item => item && item.trim() !== '');
          this.editForm.mainTag = realMainTags.join(',');
          let url = '/shareProj/saveShare';
          if (this.isEdit) {
            url = '/shareProj/updateShare';
          }
          this.postRequest(url, this.editForm).then(resp => {
            if (resp) {
              let alertMsg = "发布成功，点击确定跳转到分享列表页面!";
              if (isTempSave) {
                alertMsg = '已成功保存草稿，点击确定跳转到分享列表页面!';
              }
              alert(alertMsg);
              _this.$router.push('/admin/shareList')
            }
          })
        } else {
          return false;
        }
      });
    },
  }
}
</script>

<style lang='scss'>
.m_container {
  margin-top: 20px;
}
.custom-size .phone {
  top: 1%;
  .phone-con {
    padding: 30px 0;
    overflow-y: auto;
    p {
      margin-top: 0;
      img {
        width: 100%;
      }
    }

  }
}

.isimages {
  position: relative;
  display: inline-block;
  margin-right: 8px;
  .preview_size {
    width: 96px;
    height: 96px;
    border-radius: 5px
  }
}

.isimages:hover .imgs_prews {
  display: block;
}

.imgs_prews {
  display: none;
}

.imgs_prew {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: #fff;
  background: rgba(0, 0, 0, .3);
}

.imgs_prew I {
  display: inline-block;
  font-size: 20px;
  cursor: pointer;
}

.imgs_prew span {
  width: 2px;
  height: 18px;
  margin: 0 8px;
}

</style>