<template>
  <div class="m_container">
    <!-- 项目内容 -->
    <div class="m_content">
      <el-form ref="editForm" status-icon :model="editForm" :rules="projectRules" label-width="180px">
        <div class="form-part-section">
          <el-form-item label="标题" prop="title">
            <el-input v-model="editForm.title"></el-input>
          </el-form-item>
          <el-form-item label="项目名称" prop="projName">
            <el-input v-model="editForm.projName"></el-input>
          </el-form-item>
          <el-form-item label="项目英文名">
            <el-input v-model="editForm.projEnName"></el-input>
          </el-form-item>
          <el-form-item label="项目定位" prop="description">
            <el-input v-model="editForm.description" placeholder="如嘉定新城标杆商业中心"></el-input>
          </el-form-item>
          <el-form-item label="项目VR全景链接">
            <el-input v-model="editForm.vrLink" placeholder="请输入项目整体VR全景链接，户型具体VR链接请勿在此填写"></el-input>
          </el-form-item>
        </div>
        <div class="form-part-section">
          <el-form-item label="土地性质" prop="landType">
            <el-select v-model="editForm.landType" style="width:50%" placeholder="请选择">
              <el-option v-for="(item,index) in landTypeList" :key="index" :label="item.name"
                         :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="土地年限">
            <el-input type="number" v-model="editForm.landLimit"></el-input>
          </el-form-item>
          <el-form-item label="土地信息客户是否可见">
            <el-switch
                v-model="editForm.ltPublic"
                active-text="可见"
                inactive-text="隐藏">
            </el-switch>
          </el-form-item>
        </div>
        <div class="form-part-section">
          <el-form-item label="项目标签" required>
            <el-select v-model="selTags" style="width:50%" multiple placeholder="请选择标签（可多选）">
              <el-option v-for="(tagItem,tagIndex) in getAllTags" :key="tagIndex" :label="tagItem.name"
                         :value="tagItem.id + ''"></el-option>
            </el-select>
            <el-button type="primary" size="small" @click="dialog_add = true" style="margin-left: 10px;">新建标签
            </el-button>
          </el-form-item>
          <el-form-item label="项目类别" prop="typeId">
            <el-select v-model="editForm.typeId" placeholder="请选择项目类别">
              <el-option v-for="(item,index) in getAllTypes" :key="index" :label="item.name"
                         :value="item.id + ''"></el-option>
            </el-select>
            <el-button type="primary" size="small" @click="dialog2 = true" style="margin-left: 10px;">新建项目分类</el-button>
          </el-form-item>
          <el-form-item label="展示序列(0为第一展示)">
            <el-input type="number" v-model="editForm.sort"></el-input>
          </el-form-item>
        </div>
        <div class="form-part-section">
          <el-form-item label="所在区域" required>
            <v-region v-model="selectedAreas" @values="regionChange"></v-region>
          </el-form-item>
          <el-form-item label="详细地址">
            <el-input v-model="editForm.detailAddr"></el-input>
          </el-form-item>
          <el-form-item label="地图定位">
          <span v-show="editForm.longitude"
                style="margin-right: 10px;">经度：{{ editForm.longitude }}, 纬度：{{ editForm.latitude }}</span>
            <el-button size="mini" type="primary" @click="searchMapAddr">定位</el-button>
          </el-form-item>
          <el-form-item label="所属商圈">
            <el-input
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 4}"
                placeholder="所属商圈简介"
                v-model="editForm.belongCbd">
            </el-input>
          </el-form-item>
          <el-form-item label="周边交通情况">
            <el-input
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 4}"
                placeholder="周边交通情况简介"
                v-model="editForm.trafficInfo">
            </el-input>
          </el-form-item>
          <el-form-item label="面积范围（㎡）">
            <numberRange :startValue.sync="editForm.minArea" :endValue.sync="editForm.maxArea" :precision="2" />
          </el-form-item>
          <el-form-item label="售价范围（元/㎡）">
            <numberRange :startValue.sync="editForm.minPrice" :endValue.sync="editForm.maxPrice" :precision="2" />
          </el-form-item>
          <el-form-item label="租金范围（元/㎡/天）">
            <numberRange :startValue.sync="editForm.minRentPrice" :endValue.sync="editForm.maxRentPrice" :precision="2" />
          </el-form-item>
        </div>
        <div class="form-part-section">
          <el-form-item label="物业类型" prop="propertyType">
            <el-select v-model="editForm.propertyType" style="width:50%" placeholder="请选择">
              <el-option v-for="(item,index) in propertyTypeList" :key="index" :label="item.name"
                         :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="物业费(元/㎡/月)">
            <el-input type="number" v-model="editForm.propertyPrice"></el-input>
          </el-form-item>
          <el-form-item label="物业费说明">
            <el-input v-model="editForm.propertyRemark" placeholder="如包含空调维护费等"></el-input>
          </el-form-item>
          <el-form-item label="物业费信息客户是否可见">
            <el-switch
                v-model="editForm.propertyPublic"
                active-text="可见"
                inactive-text="隐藏">
            </el-switch>
          </el-form-item>
        </div>
        <div class="form-part-section">
          <el-form-item label="付款方式">
            <el-input
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 4}"
                placeholder="付款方式描述，如付三押二等"
                v-model="editForm.payTypeDesc">
            </el-input>
          </el-form-item>
          <el-form-item label="优惠政策">
            <el-input
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 4}"
                placeholder="优惠政策描述"
                v-model="editForm.discountDesc">
            </el-input>
          </el-form-item>
        </div>
        <div class="form-part-section">
          <el-form-item label="总停车位数">
            <el-input type="number" v-model="editForm.parkingNum"></el-input>
          </el-form-item>
          <el-form-item label="车位价格（元/个）">
            <el-input type="number" v-model="editForm.parkPrice"></el-input>
          </el-form-item>
          <el-form-item label="免费临停时长（分钟）">
            <el-input type="number" v-model="editForm.freeParkMin"></el-input>
          </el-form-item>
          <el-form-item label="临停车费（元/时）">
            <el-input type="number" v-model="editForm.parkFee"></el-input>
          </el-form-item>
          <el-form-item label="临停车费上限（元）">
            <el-input type="number" v-model="editForm.maxParkFee"></el-input>
          </el-form-item>
          <div class="el-row--flex">
            <el-form-item label="月租停车费（元/月）">
              <el-input type="number" v-model="editForm.monthParkFee"></el-input>
            </el-form-item>
            <el-form-item label="其中车位租金（元/月）">
              <el-input type="number" v-model="editForm.monthParkFeePur"></el-input>
            </el-form-item>
            <el-form-item label="车位管理费（元/月）">
              <el-input type="number" v-model="editForm.monthParkFeeOrg"></el-input>
            </el-form-item>
          </div>
          <el-form-item label="最小长租车位月数">
            <el-input type="number" v-model="editForm.minMonth"></el-input>
          </el-form-item>
          <el-form-item label="长租公共车位位置">
            <el-select v-model="editForm.parkType" placeholder="请选择">
              <el-option v-for="(item,index) in parkTypeList" :key="index" :label="item"
                         :value="index"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="停车费信息客户是否可见">
            <el-switch
                v-model="editForm.parkPublic"
                active-text="可见"
                inactive-text="隐藏">
            </el-switch>
          </el-form-item>
        </div>
        <div class="form-part-section">
          <el-form-item label="公开程度">
            <el-select v-model="editForm.published" placeholder="请选择公开程度">
              <el-option v-for="(item,index) in publishTypeList" :key="index" :label="item"
                         :value="index"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="信息来源">
            <el-select v-model="editForm.flag" placeholder="请选择信息来源">
              <el-option v-for="(item,index) in createFlagList" :key="index" :label="item"
                         :value="index + ''"></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="form-part-section">
          <el-form-item label="项目状态">
            <el-select v-model="editForm.projStatus" placeholder="请选择项目状态">
              <el-option v-for="(item,index) in projStatusList" :key="index" :label="item"
                         :value="index"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="招商开始日期">
            <el-date-picker
                v-model="editForm.startTime"
                type="date"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="招商结束日期">
            <el-date-picker
                v-model="editForm.finishTime"
                type="date"
                value-format="yyyy-MM-dd HH:mm:ss"
                :disabled="editForm.startTime === ''"
                @change="changeFinishTime"
                placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
        </div>
        <div class="form-part-section">
          <el-form-item label="周边现有配套设施">
            <el-input
                type="textarea"
                :autosize="{ minRows: 4, maxRows: 6}"
                placeholder="请输入周边现有配套设施描述"
                v-model="editForm.existSupport">
            </el-input>
          </el-form-item>
          <el-form-item label="周边规划配套设施">
            <el-input
                type="textarea"
                :autosize="{ minRows: 4, maxRows: 6}"
                placeholder="请输入周边规划配套设施描述"
                v-model="editForm.planSupport">
            </el-input>
          </el-form-item>
        </div>
        <div class="form-part-section">
          <el-form-item label="封面图片" prop="firstPicture">
            <single-upload v-model="editForm.firstPicture"></single-upload>
          </el-form-item>
          <el-form-item label="相关视频：">
            <multi-upload-video :fileList="selectProjectVideos" :limit="1" @submitVideo="bindProjectVideo"></multi-upload-video>
          </el-form-item>
          <el-form-item label="项目笔记" prop="content">
            <tinymce ref="mec" :height="300" v-model="editForm.content"></tinymce>
          </el-form-item>
        </div>
        <el-form-item style="margin: 60px; text-align: center;">
          <el-button type="info" v-if="!isEdit" @click="toSubmitForm('editForm', true)">暂时保存</el-button>
          <el-button type="primary" @click="toSubmitForm('editForm', false)">发布项目</el-button>
          <el-button type="info" v-if="isEdit" @click="goBack">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 新建项目分类 -->
    <el-dialog
        title="新建项目类别"
        :visible.sync="dialog2"
        width="30%"
    >
      <el-form status-icon :model="type" ref="type" :rules="rules2" label-width="120px">
        <el-form-item label="项目类别名" prop="name">
          <el-input v-model="type.name" placeholder="请输入要新建的项目类别名"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button @click="dialog2 = false">取 消</el-button>
          <el-button type="primary" @click="submitForm('type')">确 定</el-button>
        </span>
    </el-dialog>

    <!-- 对话框 新建标签 -->
    <el-dialog
        title="新建标签"
        :visible.sync="dialog_add"
        width="30%">
      <el-form status-icon label-width="120px">
        <el-form-item label="标签名称">
          <el-input v-model="tag_add.name" placeholder="请输入要新建的标签名称"
                    :rules="{required: true, message: '不能为空', trigger: 'blur'}"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
           <el-button @click="dialog_add = false">取 消</el-button>
           <el-button type="primary" @click="addNewTag">确 定</el-button>
         </span>
    </el-dialog>

    <div>
      <el-dialog
          title="请标记地图定位"
          :visible.sync="mapDialogVisible"
          width="60%">
        <map-child :address="editForm.detailAddr" :latitude="mapLat" :longitude="mapLng" v-if="isResetComponent"
                   @mapSend="addrMark"></map-child>
        <span slot="footer" class="dialog-footer">
        <el-button @click="handleMapCancel()" size="small">取 消</el-button>
        <el-button type="primary" @click="mapDialogVisible = false" size="small">确 定</el-button>
      </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import SingleUpload from '@/components/Upload/singleUpload'
import MultiUploadVideo from '@/components/Upload/multiUploadVideo'
import numberRange from "@/components/util/number-range.vue";
import mapChild from '@/components/qqMap/map'
import Tinymce from '@/components/Tinymce'
import {jsonp} from "vue-jsonp";

const defaultProject = {  //项目表单
  id: null,
  title: '',
  projName: '',
  projEnName: '',
  description: '',
  firstPicture: '',
  adVideo: '',
  vrLink: '',
  content: '',
  typeId: '',
  flag: '0',
  published: 1,
  shareStatement: 0,
  projStatus: 0,
  landType: 1,
  landLimit: null,
  ltPublic: true,
  propertyType: 1,
  propertyPrice: null,
  propertyRemark: null,
  propertyPublic: true,
  parkPrice: null,
  payTypeDesc: null,
  discountDesc: null,
  parkingNum: null,
  freeParkMin: null,
  parkFee: null,
  maxParkFee: null,
  monthParkFee: null,
  monthParkFeePur: null,
  monthParkFeeOrg: null,
  minMonth: null,
  parkType: null,
  parkPublic: true,
  startTime: '',
  finishTime: '',
  areaCodes: '',
  areaName: '',
  cityCodes: '',
  cityName: '',
  detailAddr: '',
  latitude: '',
  longitude: '',
  existSupport: '',
  planSupport: '',
  belongCbd: '',
  trafficInfo: '',
  minPrice: null,
  maxPrice: null,
  minRentPrice: null,
  maxRentPrice: null,
  minArea: null,
  maxArea: null,
  sort: null,
  tags: []
};

export default {
  name: "projectDetail",
  props: {
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  components: {SingleUpload, mapChild, Tinymce, MultiUploadVideo,numberRange},
  created() {
    this.initTags();
    this.initProject();
    this.initType();
  },
  computed: {
    selectProjectVideos: {
      get: function () {
        let videos = [];
        if (!this.editForm.adVideo || this.editForm.adVideo === '') {
          return videos;
        }
        let tmpVideos = this.editForm.adVideo.split(',');
        for (let i = 0; i < tmpVideos.length; i++) {
          videos.push(tmpVideos[i]);
        }
        return videos;
      },
      set: function (newValue) {
        if (newValue == null || newValue.length === 0) {
          this.editForm.adVideo = null;
        } else {
          this.editForm.adVideo = newValue.join(',');
        }
      }
    }
  },
  data() {
    return {
      editForm: Object.assign({}, defaultProject),
      type: {  //项目分类
        name: ''
      },
      tag_add: {
        name: '',  //标签名称
      },
      selectedAreas: {
        province: '',
        city: '',
        area: '',
        town: ''
      },
      disabledDate(time) {
        return time.getTime() < this.editForm.startTime
      },
      uniqueId: "uniqueUploadId",
      tmpPicture: '',
      dataObj: {
        policy: '',
        signature: '',
        key: '',
        ossaccessKeyId: '',
        dir: '',
        host: ''
      },
      getAllTypes: [], // 所有项目分类
      getAllTags: [], // 所有项目标签
      selTags: [],
      landTypeList: [ // 1、商办用地；2、工业用地；3、商业用地；4、研发用地；5、飞地；6、其他用地
        {
          id: 1,
          name: '商办用地'
        }, {
          id: 2,
          name: '工业用地'
        }, {
          id: 3,
          name: '商业用地'
        }, {
          id: 4,
          name: '研发用地'
        }, {
          id: 5,
          name: '飞地'
        }, {
          id: 6,
          name: '其他用地'
        }
      ],
      propertyTypeList: [ // 物业类型：1、写字楼；2、产业园；3、商铺；4、公寓；5、住宅；6、其他
        {
          id: 1,
          name: '写字楼'
        }, {
          id: 2,
          name: '产业园'
        }, {
          id: 3,
          name: '商铺'
        }, {
          id: 4,
          name: '公寓'
        }, {
          id: 5,
          name: '住宅'
        }, {
          id: 6,
          name: '其他'
        }
      ],
      publishTypeList: ['内部', '公开'],
      parkTypeList: ['通用', '地面', '地下'],
      createFlagList: ['原创', '转载'],
      projStatusList: ['进行中', '即将开始', '即将完成', '已完成'],
      multiCities: ['110000', '120000', '310000', '500000'],
      dialogFormVisible: false,  //控制发布项目对话框
      dialog2: false,  //控制新增项目分类对话框
      dialog_add: false,
      mapLat: '',
      mapLng: '',
      oldLatLng: {
        lat: '',
        lng: ''
      },
      mapDialogVisible: false,
      isResetComponent: true,
      projectRules: {  //表单校验
        title: [
          {required: true, message: '请输入标题', trigger: 'blur'},
          {min: 3, max: 100, message: '长度在 3 到 100 个字符', trigger: 'blur'}
        ],
        projName: [
          {required: true, message: '请输入项目名', trigger: 'blur'}
        ],
        description: [
          {required: true, message: '请输入项目定位', trigger: 'blur'}
        ],
        landType: [
          {required: true, message: '请选择土地性质', trigger: 'blur'}
        ],
        propertyType: [
          {required: true, message: '请选择物业类型', trigger: 'blur'}
        ],
        typeId: [
          {required: true, message: '项目类别不能为空', trigger: 'blur'}
        ],
        firstPicture: [
          {required: true, message: '请选择封面图片', trigger: 'blur'}
        ],
        content: [
          {required: true, message: '请输入项目内容', trigger: 'blur'}
        ],
      },
      rules2: { //表单校验
        name: [
          {required: true, message: '项目类别名不能为空', trigger: 'blur'}
        ],
      }
    }
  },
  methods: {
    //初始化数据
    initProject() {
      if (this.isEdit) {
        const _this = this
        let queryId = this.$route.query.id;
        this.getRequest('/project/getProjectById?id=' + queryId).then(resp => {
          if (!resp.obj) {
            return;
          }
          _this.editForm = resp.obj;
          _this.btags = resp.obj.tprojectTags
          let tmpTags = [];
          if (_this.btags) {
            _this.btags.forEach(element => {
              tmpTags.push(element.ttag.id + '');
            });
          }
          _this.editForm.tags = tmpTags
          _this.selTags = tmpTags;
          _this.editForm.published = resp.obj.published ? 1 : 0;
          _this.editForm.typeId = _this.editForm.typeId + '';
          if (resp.obj.areaCodes && resp.obj.areaCodes !== '') {
            let tmpAreas = resp.obj.areaCodes.split(',');
            for (let i = 0; i < tmpAreas.length; i++) {
              if (i === 0) {
                _this.selectedAreas.province = tmpAreas[i];
              } else if (i === 1) {
                _this.selectedAreas.city = tmpAreas[i];
              } else if (i === 2) {
                _this.selectedAreas.area = tmpAreas[i];
              } else {
                _this.selectedAreas.town = tmpAreas[i];
              }
            }
          }

          _this.oldLatLng.lat = _this.editForm.latitude;
          _this.oldLatLng.lng = _this.editForm.longitude;
          if (_this.editForm.latitude && _this.editForm.latitude !== '') {
            _this.mapLat = _this.editForm.latitude;
            _this.mapLng = _this.editForm.longitude;
            _this.rebuildComponents();
          } else {
            // 根据IP获取默认经纬度
            _this.getDefaultLanLng();
          }
        })
      } else {
        // 根据IP获取默认经纬度
        this.getDefaultLanLng();
      }
    },
    getDefaultLanLng() {
      const that = this;
      jsonp('https://apis.map.qq.com/ws/location/v1/ip/', {
        output: 'jsonp',
        key: 'DBQBZ-ITTCD-MFO4M-PIIT2-PCSTT-WOBWW'
      })
          .then(function (response) {
            // console.log(response)
            if (response.result.location.lat) {
              that.mapLat = response.result.location.lat;
              that.mapLng = response.result.location.lng;
            }
            that.rebuildComponents();
          })
          .catch(function (error) {
            console.log(error)
          })
    },
    changeFinishTime(time) {
      if (time.getTime() < this.editForm.startTime.getTime()) {
        this.$message({
          type: 'warning',
          message: '结束日期不能早于开始日期！'
        });
        this.editForm.finishTime = '';
      }
    },
    goBack() {
      this.$router.back();
    },
    regionChange(datas) {
      let tmpCities = '';
      let tmpArea = '';
      if (datas) {
        if (datas.province) {
          tmpCities = datas.province.value;
          tmpArea = datas.province.value;
        }
        if (datas.city || datas.area) {
          let province = datas.province.key || '';
          let isMulti = false;
          if (province && province !== '') {
            const pIdx = this.multiCities.indexOf(province);
            if (pIdx > -1) {
              isMulti = true;
            }
          }
          if (!isMulti) {
            if (datas.city) {
              tmpCities = tmpCities + datas.city.value;
              tmpArea = tmpArea + datas.city.value;
            }
          }
          if (datas.area) {
            tmpArea = tmpArea + datas.area.value;
            if (isMulti) {
              tmpCities = tmpCities + datas.area.value;
            }
          }
        }

        if (datas.town) {
          tmpArea = tmpArea + datas.town.value;
        }
      }
      this.editForm.areaName = tmpArea;
      this.editForm.cityName = tmpCities;
    },
    rebuildComponents() {
      this.isResetComponent = false;
      // 重新创建子标签
      let _this = this;
      this.$nextTick(() => {
        _this.isResetComponent = true;
      });
    },
    searchMapAddr() {
      this.mapDialogVisible = true;
    },
    addrMark(params) {
      this.editForm.latitude = params.latitude;
      this.editForm.longitude = params.longitude;
    },
    handleMapCancel() {
      this.editForm.latitude = this.oldLatLng.lat;
      this.editForm.longitude = this.oldLatLng.lng;
      this.mapDialogVisible = false;
    },
    //初始化标签列表
    initTags() {
      const _this = this
      this.getRequest('/tag/getAllTag').then(resp => {
        _this.getAllTags = resp.obj
      })
    },
    //添加标签
    addNewTag() {
      const _this = this
      this.postRequest('/tag/saveTag', this.tag_add).then(resp => {
        _this.input_name = ''
        _this.initTags()
        _this.dialog_add = false
      })
    },
    //初始化类别
    initType() {
      const _this = this
      this.getRequest('/type/getAllTypes').then(resp => {
        _this.getAllTypes = resp.obj
      })
    },
    //添加新的项目分类
    addNewType() {
      const _this = this
      this.postRequest('/type/saveType', this.type).then(resp => {
        if (resp) {
          _this.type.name = ''
          _this.initType()
          _this.dialog2 = false
        }
      })
    },
    //校验添加项目分类表单
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.addNewType()
        } else {
          return false;
        }
      });
    },
    //校验项目基本内容表单
    toSubmitForm(formName, isTempSave) {
      if (!this.selectedAreas.area || this.selectedAreas.area === '') {
        this.$message({
          type: 'warning',
          message: '请选择所在区域！'
        });

        return;
      }

      if (this.selTags.length === 0) {
        this.$message({
          type: 'warning',
          message: '请至少设置一个项目标签！'
        });

        return;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const _this = this;
          let tmpAreas = [];
          let tmpCities = [];
          let isMulti = false;
          if (this.selectedAreas.province && this.selectedAreas.province !== '') {
            const pIdx = this.multiCities.indexOf(this.selectedAreas.province);
            if (pIdx > -1) {
              isMulti = true;
            }
            tmpAreas.push(this.selectedAreas.province);
            tmpCities.push(this.selectedAreas.province);
          }
          if (this.selectedAreas.city && this.selectedAreas.city !== '') {
            tmpAreas.push(this.selectedAreas.city);
            tmpCities.push(this.selectedAreas.city);
          }
          if (this.selectedAreas.area && this.selectedAreas.area !== '') {
            tmpAreas.push(this.selectedAreas.area);
            if (isMulti) { // 直辖市保存区级
              tmpCities.push(this.selectedAreas.area);
            }
          }
          if (this.selectedAreas.town && this.selectedAreas.town !== '') {
            tmpAreas.push(this.selectedAreas.town);
          }
          this.editForm.areaCodes = tmpAreas.join(',');
          this.editForm.cityCodes = tmpCities.join(',');
          this.editForm.shareStatement = isTempSave ? 0 : 1;
          this.editForm.tags = this.selTags;
          let url = '/project/saveBT';
          if (this.isEdit) {
            url = '/project/updateProject';
          }
          this.postRequest(url, this.editForm).then(resp => {
            if (resp) {
              let alertMsg = "发布成功，点击确定跳转到项目列表页面!";
              if (isTempSave) {
                alertMsg = '已成功保存草稿，点击确定跳转到项目列表页面!';
              }
              alert(alertMsg);
              _this.$router.push('/admin/allProjects')
            }
          })
        } else {
          return false;
        }
      });
    },
    bindProjectVideo(videoList) {
      if (videoList == null || videoList.length === 0) {
        this.editForm.adVideo = null;
      } else {
        let tmpList = [];
        for (let tmpVideo of videoList) {
          tmpList.push(tmpVideo.url);
        }
        this.editForm.adVideo = tmpList.join(',');
      }
    }
  }
}
</script>

<style lang='scss'>
.m_container {
  margin-top: 20px;
}
</style>