<template>
  <div>
    <el-card style="margin-top: 10px;" shadow="never">
      <div>
        <i class="el-icon-search"></i>
        <span>筛选搜索</span>
        <el-button
            style="float: right"
            @click="handleSearchList()"
            type="primary"
            size="small">
          查询结果
        </el-button>
        <el-button
            style="float: right;margin-right: 15px"
            @click="handleResetSearch()"
            size="small">
          重置
        </el-button>
      </div>
      <div style="margin-top: 15px">
        <el-form :inline="true" :model="listQuery" size="small" label-width="140px">
          <el-form-item label="输入关键词：">
            <el-input style="width: 203px" v-model="listQuery.keyword" placeholder="分享关键词"></el-input>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
    <el-card shadow="never">
      <i class="el-icon-tickets"></i>
      <span style="font-size: 24px;">分享项目列表</span>
      <el-button size="mini" style="float: right;" @click="handleAdd()">添加</el-button>
    </el-card>
    <div>
      <el-table
          :data="list"
          style="width: 100%"
          border>
        <el-table-column
            label="编号"
            width="50" align="center">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="标题" align="center">
          <template slot-scope="scope">
            {{ scope.row.title }}
          </template>
        </el-table-column>
        <el-table-column
            label="关联项目" align="center">
          <template slot-scope="scope">
            {{ scope.row.projectName ? scope.row.projectName : '无' }}
            <span><el-button
                size="mini" type="text"
                @click="bindProj(scope.row)">更改
              </el-button></span>
          </template>
        </el-table-column>
        <el-table-column
            label="创建时间" align="center">
          <template slot-scope="scope">
            {{ scope.row.createTime ? scope.row.createTime : '--' }}
          </template>
        </el-table-column>
        <el-table-column label="分享链接" width="120" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.shareStatement && scope.row.shareQrcode"><img style="height: 80px" :src="scope.row.shareQrcode"></div>
            <div v-else>无分享链接</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="180px" align="center">
          <template slot-scope="scope">
            <p>
            <el-button
                size="mini"
                @click="handleEdit(scope.$index, scope.row)">编辑
            </el-button>
              <el-button
                  size="mini"
                  @click="viewTelList(scope.row)">电话列表
              </el-button>
            </p>
            <p>
              <el-button
                  size="mini" :disabled="scope.row.shareStatement"
                  @click="handlePublish(scope.$index, scope.row)">发布
              </el-button>
              <el-button
                  size="mini"
                  type="danger"
                  @click="handleDelete(scope.$index, scope.row)">删除
              </el-button>
            </p>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 分页 -->
    <div style="margin-top: 20px;">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="listQuery.pageNum"
          :page-size="listQuery.pageSize"
          layout="total, prev, pager, next"
          :total="total">
      </el-pagination>
    </div>
    <el-dialog
        title="更改关联项目"
        :visible.sync="dialogVisible"
        width="40%">
      <el-form :model="editForm"
               ref="bindProjForm"
               v-loading="loading"
               element-loading-text="正在获取项目列表..."
               element-loading-spinner="el-icon-loading"
               element-loading-background="rgba(0, 0, 0, 0.6)"
               label-width="150px" size="small">
        <el-form-item label="关联项目:">
          <el-select v-model="bindIdx" @change="handleBindChange" class="input-width" placeholder="请选择" filterable clearable @clear="handleClearBind">
            <el-option v-for="(item, index) in projList"
                       :key="index"
                       :label="item.projName"
                       :value="index">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="handleBindConfirm()" size="small">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

const defaultListQuery = {
  pageNum: 1,
  pageSize: 10,
  keyword: ""
};

const defaultBindForm = {
  value: null,
  label: null
};

export default {
  name: 'shareList',
  data() {
    return {
      listQuery: Object.assign({}, defaultListQuery),
      list: [],  //分类数据
      total: 0, //总记录数
      dialogVisible: false,
      editForm: Object.assign({}, defaultBindForm),
      selShareId: null,
      projList: [],
      bindIdx: 0,
      loading:false  //加载样式
    }
  },
  created() {
    this.getList();
  },
  methods: {
    //初始化分类数据
    getList() {
      const _this = this
      this.listQuery.parentId = this.parentId;
      this.getRequest('/shareProj/getByPage', this.listQuery).then(resp => {
        if (resp.obj) {
          _this.list = resp.obj.list
          _this.total = resp.obj.total
        }
      })
    },
    handleAdd() {
      this.$router.push({path: '/admin/shareDetail'});
    },
    //编辑分类
    handleEdit(index, row) {
      this.$router.push({path: '/admin/shareDetail', query: {id: row.id}});
    },
    //删除分类
    handleDelete(index, row) {
      const _this = this
      _this.$confirm('此操作将删除该分享页面, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        _this.deleteRequest('/shareProj/deleteShare?id=' + row.id).then(resp => {
          if (resp) {
            _this.getList()
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    handlePublish(index, row) {
      const _this = this
      _this.$confirm('正式发布该分享页面?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        _this.postRequest('/shareProj/publishShare?id=' + row.id).then(resp => {
          if (resp) {
            _this.getList()
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    bindProj(row) {
      this.dialogVisible = true;
      this.loading = true;
      this.selShareId = row.id;
      this.getProjList(row.relateProject);
    },
    getProjList(projId) {
      if (this.projList.length === 0) { // 没有拿过，则从服务端获取
        this.getRequest('/project/getSimpleInfoByKeyword').then(response => {
          this.projList = response.obj || [];
          let tmpIndx =this.projList.findIndex(function (item) {
            // 根据item中的id属性来判断这个item是否是在上面的id中对应的数据，如果是返回true，否返回false，继续下面一条数据的遍历,以此类推
            return item.id === projId;
            // 如果返回true,那么findIndex()会在这个item对应的id返回到外面接收
          });

          if (tmpIndx === -1) {
            this.bindIdx = 0;
          } else {
            this.bindIdx = tmpIndx || 0;
          }

          this.loading = false;
        });
      } else {
        let tmpIndx =this.projList.findIndex(function (item) {
          // 根据item中的id属性来判断这个item是否是在上面的id中对应的数据，如果是返回true，否返回false，继续下面一条数据的遍历,以此类推
          return item.id === projId;
          // 如果返回true,那么findIndex()会在这个item对应的id返回到外面接收
        });
        this.bindIdx = tmpIndx || 0;
        this.loading = false;
      }
    },
    handleBindChange(idx) {
      let tmpProj = this.projList[idx];
      this.editForm.value = tmpProj.id;
      this.editForm.label = tmpProj.projName;
    },
    handleClearBind() {
      this.editForm.value = null;
      this.editForm.label = null;
    },
    handleBindConfirm() {
      this.$confirm('是否要确认关联?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let url = '/shareProj/bindProject/' + this.selShareId;
        this.postRequest(url, this.editForm).then(resp => {
          this.$message({
            message: '关联成功！',
            type: 'success'
          });
          this.dialogVisible = false;
          this.getList();
        })
      })
    },
    viewTelList(row) {
      this.$router.push({path: '/admin/shareCustList', query: {id: row.id}});
    },
    handleSearchList() {
      this.listQuery.pageNum = 1;
      this.getList();
    },
    handleResetSearch() {
      this.listQuery = Object.assign({}, defaultListQuery);
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.listQuery.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.listQuery.pageNum = val;
      this.getList()
    },
  }
}
</script>

<style scoped>

</style>