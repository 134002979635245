<template>
  <div>
    <div>
      <el-form ref="editForm" status-icon :model="editForm" :rules="projectRules" label-width="180px">
        <div class="form-part-section">
          <el-form-item label="当前状态">
            <el-select v-model="editForm.itemStatus" placeholder="请选择">
              <el-option
                  v-for="item in itemStatusOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="form-part-section" v-if="editForm.itemStatus === 4">
          <el-form-item label="成交总结">
            <el-input
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 4}"
                placeholder="请输入成交总结"
                v-model="editForm.finalCondition">
            </el-input>
          </el-form-item>
        </div>
        <div class="form-part-section">
          <el-form-item label="标题" prop="title">
            <el-input
                placeholder="请输入标题"
                v-model="editForm.title">
            </el-input>
          </el-form-item>
          <el-form-item label="户型" prop="houseLayout">
            <el-input v-model="editForm.houseLayout"></el-input>
          </el-form-item>
          <el-form-item label="房屋类型" prop="houseType">
            <el-select v-model="editForm.houseType" placeholder="请选择">
              <el-option
                  v-for="item in houseTypeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="户型简介">
            <el-input
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 4}"
                placeholder="请输入户型简介"
                v-model="editForm.description">
            </el-input>
          </el-form-item>
          <el-form-item label="户型楼层">
            <el-input type="number" v-model="editForm.floorNum"></el-input>
          </el-form-item>
          <el-form-item label="房号">
            <el-input
                placeholder="请输入房号"
                v-model="editForm.roomNum">
            </el-input>
          </el-form-item>
          <el-form-item label="所处方位">
            <el-input placeholder="如位于楼层西南角" v-model="editForm.positionDesc"></el-input>
          </el-form-item>
          <el-form-item label="展示序列(0为第一展示)">
            <el-input type="number" v-model="editForm.sort"></el-input>
          </el-form-item>
        </div>
        <div class="form-part-section">
          <el-form-item label="最低售卖单价（元/㎡）" required v-if="editForm.houseType === 1">
            <el-input type="number" v-model="editForm.unitPrice"></el-input>
          </el-form-item>
          <el-form-item label="最低租赁单价（元/㎡/天）" required v-if="editForm.houseType === 0">
            <el-input type="number" v-model="editForm.rentUnitPrice"></el-input>
          </el-form-item>
          <el-form-item label="单价信息客户是否可见">
            <el-switch
                v-model="editForm.unitPublic"
                active-text="可见"
                inactive-text="隐藏">
            </el-switch>
          </el-form-item>
        </div>
        <div class="form-part-section">
          <el-form-item :label="(editForm.houseType === 0 ? '月租' : '总价') + '（元）'">
            <el-input type="number" v-model="editForm.totalPrice"></el-input>
          </el-form-item>
          <el-form-item label="总价信息客户是否可见">
            <el-switch
                v-model="editForm.totalPublic"
                active-text="可见"
                inactive-text="隐藏">
            </el-switch>
          </el-form-item>
        </div>
        <div class="form-part-section">
          <el-form-item label="价格构成">
            <el-input
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 4}"
                placeholder="请输入价格构成"
                v-model="editForm.priceInclude">
            </el-input>
          </el-form-item>
          <el-form-item label="价格构成信息客户是否可见">
            <el-switch
                v-model="editForm.includePublic"
                active-text="可见"
                inactive-text="隐藏">
            </el-switch>
          </el-form-item>
        </div>
        <div class="form-part-section">
          <el-form-item label="层高（米）">
            <el-input type="number" v-model="editForm.floorHeight"></el-input>
          </el-form-item>
          <el-form-item label="层高信息客户是否可见">
            <el-switch
                v-model="editForm.heightPublic"
                active-text="可见"
                inactive-text="隐藏">
            </el-switch>
          </el-form-item>
        </div>
        <div class="form-part-section">
          <el-form-item label="净层高（米）">
            <el-input type="number" v-model="editForm.pureFloorHeight"></el-input>
          </el-form-item>
          <el-form-item label="净层高信息客户是否可见">
            <el-switch
                v-model="editForm.pfPublic"
                active-text="可见"
                inactive-text="隐藏">
            </el-switch>
          </el-form-item>
        </div>
        <div class="form-part-section">
          <el-form-item label="承重（kg）">
            <el-input type="number" v-model="editForm.floorWeight"></el-input>
          </el-form-item>
          <el-form-item label="承重信息客户是否可见">
            <el-switch
                v-model="editForm.weightPublic"
                active-text="可见"
                inactive-text="隐藏">
            </el-switch>
          </el-form-item>
        </div>
        <div class="form-part-section">
          <el-form-item label="架空地板高度（cm）">
            <el-input type="number" v-model="editForm.raisedFloor"></el-input>
          </el-form-item>
          <el-form-item label="架空高度信息客户是否可见">
            <el-switch
                v-model="editForm.rfPublic"
                active-text="可见"
                inactive-text="隐藏">
            </el-switch>
          </el-form-item>
        </div>
        <div class="form-part-section">
          <el-form-item label="建筑面积（㎡）">
            <el-input type="number" v-model="editForm.floorBuildArea"></el-input>
          </el-form-item>
          <el-form-item label="建筑面积客户是否可见">
            <el-switch
                v-model="editForm.baPublic"
                active-text="可见"
                inactive-text="隐藏">
            </el-switch>
          </el-form-item>
        </div>
        <div class="form-part-section">
          <el-form-item label="实用面积（㎡）">
            <el-input type="number" v-model="editForm.floorUseArea"></el-input>
          </el-form-item>
          <el-form-item label="装修程度">
            <el-select v-model="editForm.decorateType" placeholder="请选择">
              <el-option
                  v-for="item in decorateTypeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="屋内配套设施">
            <el-input
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 4}"
                placeholder="请输入配套的屋内设施"
                v-model="editForm.fitmentDesc">
            </el-input>
          </el-form-item>
          <el-form-item label="房间朝向">
            <el-input v-model="editForm.floorDirection">
            </el-input>
          </el-form-item>
        </div>
        <div class="form-part-section">
          <el-form-item label="得房率（%）">
            <el-input type="number" v-model="editForm.roomRate"></el-input>
          </el-form-item>
          <el-form-item label="得房率客户是否可见">
            <el-switch
                v-model="editForm.rrPublic"
                active-text="可见"
                inactive-text="隐藏">
            </el-switch>
          </el-form-item>
        </div>
        <div class="form-part-section">
          <el-form-item label="封面图片" prop="firstPicture">
            <single-upload v-model="editForm.firstPicture"></single-upload>
          </el-form-item>
          <el-form-item label="相关视频：">
            <multi-upload-video :fileList="selectProjectVideos" :limit="1" @submitVideo="bindProjectVideo"></multi-upload-video>
          </el-form-item>
          <el-form-item label="VR看房链接">
            <el-input v-model="editForm.vrLink"></el-input>
          </el-form-item>
          <el-form-item label="户型相册">
            <multi-upload v-model="albums"></multi-upload>
          </el-form-item>
        </div>
        <div class="form-part-section">
          <el-form-item label="是否可注册公司">
            <el-switch
                v-model="editForm.canRegister"
                active-text="是"
                inactive-text="否">
            </el-switch>
          </el-form-item>
          <el-form-item label="预计使用工位">
            <el-input type="number" v-model="editForm.workNum"></el-input>
          </el-form-item>
          <el-form-item label="屋内是否可分割">
            <el-switch
                v-model="editForm.canSplit"
                active-text="是"
                inactive-text="否">
            </el-switch>
          </el-form-item>
          <el-form-item label="分割信息客户是否可见">
            <el-switch
                v-model="editForm.splitPublic"
                active-text="可见"
                inactive-text="隐藏">
            </el-switch>
          </el-form-item>
        </div>
        <div class="form-part-section">
          <el-form-item label="免费车位数">
            <el-input type="number" v-model="editForm.freeParkNum"></el-input>
          </el-form-item>
          <el-form-item label="免费车位数客户是否可见">
            <el-switch
                v-model="editForm.freePublic"
                active-text="可见"
                inactive-text="隐藏">
            </el-switch>
          </el-form-item>
        </div>
        <div class="form-part-section">
          <el-form-item label="起租期（月）">
            <el-input type="number" v-model="editForm.minMonth"></el-input>
          </el-form-item>
          <el-form-item label="免租期（月）">
            <el-input type="number" v-model="editForm.freeMonth"></el-input>
          </el-form-item>
          <el-form-item label="免租期客户是否可见">
            <el-switch
                v-model="editForm.fmPublic"
                active-text="可见"
                inactive-text="隐藏">
            </el-switch>
          </el-form-item>
        </div>
        <div class="form-part-section">
          <el-form-item label="供电标准（千瓦时）">
            <el-input type="number" v-model="editForm.electricStandard"></el-input>
          </el-form-item>
          <el-form-item label="供电标准客户是否可见">
            <el-switch
                v-model="editForm.esPublic"
                active-text="可见"
                inactive-text="隐藏">
            </el-switch>
          </el-form-item>
        </div>
        <div class="form-part-section">
          <el-form-item label="大门尺寸（宽*高 cm）">
            <el-input v-model="editForm.doorSize"></el-input>
          </el-form-item>
          <el-form-item label="门是否可改装">
            <el-switch
                v-model="editForm.customDoor"
                active-text="可"
                inactive-text="否">
            </el-switch>
          </el-form-item>
          <el-form-item label="改装门要求">
            <el-input
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 4}"
                placeholder="请输入改装门要求"
                v-model="editForm.customDoorRequire">
            </el-input>
          </el-form-item>
          <el-form-item label="改装门信息客户是否可见">
            <el-switch
                v-model="editForm.cdPublic"
                active-text="可见"
                inactive-text="隐藏">
            </el-switch>
          </el-form-item>
        </div>
        <div class="form-part-section">
          <el-form-item label="交付标准">
            <el-input
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 4}"
                placeholder="交付标准描述"
                v-model="editForm.deliverStandard">
            </el-input>
          </el-form-item>
          <el-form-item label="交付标准客户是否可见">
            <el-switch
                v-model="editForm.dsPublic"
                active-text="可见"
                inactive-text="隐藏">
            </el-switch>
          </el-form-item>
        </div>
        <div class="form-part-section">
          <el-form-item label="户型描述" prop="content">
            <tinymce ref="mec" :height="300" v-model="editForm.content"></tinymce>
          </el-form-item>
<!--          <el-form-item label="户型描述">-->
<!--            <mavon-editor v-model="editForm.content" ref="mec" @imgAdd="contentImgAdd" />-->
<!--          </el-form-item>-->
        </div>

        <el-form-item style="margin: 60px; text-align: center;">
          <el-button type="primary" @click="toSubmitForm('editForm')">保存</el-button>
          <el-button type="info" @click="goBack">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import multiUpload from '@/components/Upload/multiUpload'
import Tinymce from '@/components/Tinymce'
import MultiUploadVideo from "@/components/Upload/multiUploadVideo.vue";
import SingleUpload from "@/components/Upload/singleUpload.vue";

const defaultProjectItem = {  //项目具体表单
  id: null,
  projectId: null,
  title: null,
  houseLayout: '',
  houseType: 0, // 房屋类型：0-租赁；1-售卖
  floorNum: null,
  floorHeight: null,
  heightPublic: true,
  pureFloorHeight: null,
  pfPublic: true,
  floorWeight: null,
  weightPublic: true,
  raisedFloor: null,
  rfPublic: true,
  decorateType: 0, // 0-毛坯；1-简装；2-精装；3-拎包入驻
  fitmentDesc: null,
  floorBuildArea: null,
  baPublic: true,
  floorUseArea: null,
  rrPublic: true,
  roomRate: null,
  floorDirection: '',
  description: '',
  albumPics: null,
  content: '',
  canRegister: true,
  canSplit: false,
  splitPublic: true,
  payType: '',
  workNum: 0,
  freeParkNum: 0,
  freePublic: true,
  vrLink: null,
  minMonth: 0,
  freeMonth: 0,
  fmPublic: true,
  storeys: '',
  electricStandard: null,
  doorSize: '',
  esPublic: true,
  customDoorRequire: '',
  customDoor: false,
  cdPublic: true,
  deliverStandard: null,
  dsPublic: true,
  roomNum: null,
  itemStatus: 0, // 0-待售；1-意向订；2-已预付；3-已定；4-已交接完成；9-已下架
  positionDesc: null,
  unitPrice: null,
  rentUnitPrice: null,
  unitPublic: false,
  totalPrice: null,
  totalPublic: false,
  priceInclude: null,
  includePublic: false,
  finalCondition: null,
  sort: null,
  firstPicture: '',
  adVideo: ''
};
export default {
  name: "projectItemInfo",
  data() {
    return {
      editForm: Object.assign({}, defaultProjectItem),
      albums: [],
      uniqueId: "uniqueUploadId",
      projectId: null,
      houseTypeOptions: [{
        value: 0,
        label: '租赁'
      }, {
        value: 1,
        label: '售卖'
      }],
      decorateTypeOptions: [{ // 0-毛坯；1-简装；2-精装；3-拎包入驻
        value: 0,
        label: '毛坯'
      }, {
        value: 1,
        label: '简装'
      }, {
        value: 2,
        label: '精装'
      }, {
        value: 3,
        label: '拎包入驻'
      }],
      itemStatusOptions: [{ // 0-待售；1-意向订；2-已预付；3-已定；4-已交接完成；9-已下架
        value: 0,
        label: '待售'
      }, {
        value: 1,
        label: '意向订'
      }, {
        value: 2,
        label: '已预付'
      }, {
        value: 3,
        label: '已定'
      }, {
        value: 4,
        label: '已交接完成'
      }, {
        value: 9,
        label: '已下架'
      }],
      projectRules: {  //表单校验
        title: [
          {required: true, message: '请输入标题', trigger: 'blur'},
          {min: 3, max: 100, message: '长度在 3 到 100 个字符', trigger: 'blur'}
        ],
        houseLayout: [
          {required: true, message: '请输入户型', trigger: 'blur'}
        ],
        houseType: [
          {required: true, message: '请选择房屋类型', trigger: 'blur'}
        ],
      }
    }
  },
  components: {SingleUpload, MultiUploadVideo, multiUpload, Tinymce},
  created(e) {
    this.initProject();
  },
  computed: {
    selectProjectVideos: {
      get: function () {
        let videos = [];
        if (!this.editForm.adVideo || this.editForm.adVideo === '') {
          return videos;
        }
        let tmpVideos = this.editForm.adVideo.split(',');
        for (let i = 0; i < tmpVideos.length; i++) {
          videos.push(tmpVideos[i]);
        }
        return videos;
      },
      set: function (newValue) {
        if (newValue == null || newValue.length === 0) {
          this.editForm.adVideo = null;
        } else {
          this.editForm.adVideo = newValue.join(',');
        }
      }
    }
  },
  methods: {
    //初始化数据
    initProject() {
      const _this = this
      let queryId = this.$route.query.id;
      this.projectId = this.$route.query.projectId;
      if (queryId) {
        this.getRequest('/projectItem/getItemInfo/' + queryId).then(resp => {
          if (resp.obj) {
            _this.editForm = resp.obj;
            if (_this.editForm.albumPics && _this.editForm.albumPics !== '') {
              this.albums = _this.editForm.albumPics.split(',');
            }
          } else {
            _this.editForm = Object.assign({}, defaultProjectItem);
            _this.editForm.projectId = this.projectId;
          }
        })
      } else {
        _this.editForm.projectId = this.projectId;
      }
    },
    goBack() {
      this.$router.back();
    },
    //校验项目基本内容表单
    toSubmitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const _this = this;
          let url = '/projectItem/updateItem';
          if (this.albums.length > 0) {
            this.editForm.albumPics = this.albums.join(',');
          }
          if (this.editForm.houseType === 0) {
            this.editForm.unitPrice = null;
          } else {
            this.editForm.rentUnitPrice = null;
          }
          this.editForm.projectId = this.projectId;
          this.postRequest(url, this.editForm).then(resp => {
            if (resp) {
              let alertMsg = "保存成功，点击确定跳转到项目列表页面!";
              alert(alertMsg);
              _this.$router.push('/admin/allProjects')
            }
          })
        } else {
          return false;
        }
      });
    },
    // 绑定@imgAdd event
    async contentImgAdd(pos, $file){
      // 第一步.将图片上传到服务器.const _this = this;
      //构造formData对象
      let formData = new FormData();
      formData.append('file', $file);
      try {
        //调用上传文件接口
        this.postRequest('/project/uploadTmpImg', formData).then(res => {
          //返回上传文件的地址
          let url = res.obj || null;
          if (url != null && url.length > 0) {
            // 第二步.将返回的url替换到文本原位置![...](0) -> ![...](url)
            /**
             * $vm 指为mavonEditor实例，可以通过如下两种方式获取
             * 1. 通过引入对象获取: `import {mavonEditor} from ...` 等方式引入后，`$vm`为`mavonEditor`
             * 2. 通过$refs获取: html声明ref : `<mavon-editor ref=md ></mavon-editor>，`$vm`为 `this.$refs.md`
             */
            this.$refs.mec.$img2Url(pos, url);
          } else {
            this.$message.warning("图片上传失败");
          }
        });
      } catch ({message: msg}) {
        this.$message.warning(msg);
      }
    },
    bindProjectVideo(videoList) {
      if (videoList == null || videoList.length === 0) {
        this.editForm.adVideo = null;
      } else {
        let tmpList = [];
        for (let tmpVideo of videoList) {
          tmpList.push(tmpVideo.url);
        }
        this.editForm.adVideo = tmpList.join(',');
      }
    }
  }
}
</script>

<style scoped>

</style>